import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-223a83b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Zone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree_select = _resolveComponent("el-tree-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 1
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('zone.form.parentId'),
              prop: "parentId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tree_select, {
                  modelValue: _ctx.form.parentId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.parentId) = $event)),
                  data: _ctx.otherParams.zoneTreeData,
                  "render-after-expand": false,
                  "default-expand-all": "",
                  "check-strictly": "",
                  style: {"width":"100%"}
                }, null, 8, ["modelValue", "data"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('zone.form.name'),
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[1] || (_cache[1] = e => _ctx.form.name = _ctx.valid(e)),
                  modelValue: _ctx.form.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.name) = $event)),
                  placeholder: _ctx.$t('zone.form.name_placeholder'),
                  maxlength: "100",
                  disabled: _ctx.disabled,
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('zone.form.fzr'),
              prop: "fzr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[3] || (_cache[3] = e => _ctx.form.fzr = _ctx.valid(e)),
                  modelValue: _ctx.form.fzr,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.fzr) = $event)),
                  placeholder: _ctx.$t('zone.form.fzr_placeholder'),
                  maxlength: "25",
                  disabled: _ctx.disabled,
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('zone.form.contact'),
              prop: "contact"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[5] || (_cache[5] = e => _ctx.form.contact = _ctx.valid(e)),
                  modelValue: _ctx.form.contact,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.contact) = $event)),
                  placeholder: _ctx.$t('zone.form.contact_placeholder'),
                  maxlength: "30",
                  disabled: _ctx.disabled,
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('zone.form.countryId'),
              prop: "countryId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  ref: el=>_ctx.refMap.set('countryId',el),
                  modelValue: _ctx.form.countryId,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.countryId) = $event)),
                  clearable: "",
                  placeholder: _ctx.$t('zone.form.countryId_placeholder'),
                  style: {"width":"100%"},
                  multiple: "",
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 5,
                  disabled: _ctx.disabled,
                  onChange: _cache[8] || (_cache[8] = (val)=>{_ctx.selectOnChange(val,'countryId')})
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'countryId'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value,
                        disabled: item.disabled
                      }, null, 8, ["label", "value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "disabled"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}